'use strict';

angular.module('enervexSalesappApp').controller('ProductsCtrl', function($scope, $http, Product, Auth, Account, $stateParams, _, Util, Property, Flash, ProductType, ProductSubtype, FileUploader, VentMaterial, Fitting, Baffle, Manufacturer, InsulationType, MetalType, SystemTask) {
	// $scope.upload = {}
	$scope.accountId = $stateParams.accountId;
	$scope.configs = Property.configs()
	$scope.ctx = {
		deleted: ($scope.accountId) ? 'false' : '',
		showDeleteOptions: ($scope.accountId) ? false : true,
		productType: '',
		productPageSize: 700,
		duplicate: '',
		deleted: '',
		isAggregate:''
	}
	Fitting.query({active:true}).$promise.then(function(result){
		$scope.fittings = result
	})
	ProductType.query().$promise.then(function(productTypes){
		$scope.productTypes = _.filter(productTypes, function(o){
			return o.active
		})
	})
	ProductSubtype.query().$promise.then(function(productSubtypes){
		$scope.productSubtypes = _.map(productSubtypes, function(o){
			o.nameActive = o.active ? o.name : o.name + " (inactive)"
			return o
		})
	})
	Manufacturer.query().$promise.then(function(result){
		$scope.manufacturers = result
		var manufacturer = _.find(result, function(o){
			return o.isDefault
		})
		if ($scope.accountId) {
			$scope.ctx.manufacturer = manufacturer._id
		}
	})
	VentMaterial.query().$promise.then(function(result){
		$scope.ventMaterials = result
	})
	Baffle.query().$promise.then(function(result){
		$scope.baffles = result
	})
	MetalType.query().$promise.then(function(result){
		$scope.metalTypes = result
	})
	InsulationType.query().$promise.then(function(result){
		$scope.insulationTypes = result
	})

	$scope.ventDiameters = [2,3,4,5,6,7,8,9,10,12,14,16,18,20,22,24,26,28, 30, 32, 34, 36,38, 40 , 42, 44, 46, 48];
	$scope.spaced = function(value) {
		return value.split(",").join(", ")
	}
	if ($scope.accountId){
		Account.get({
			id: $stateParams.accountId
		}).$promise.then(function(res){
			$scope.account = res;
		});
	}

	$scope.toUrl = function(snippet){
		if ($scope.accountId){
			return "/accounts/"+$scope.accountId + snippet;
		} else {
			return snippet;
		}
	}

	$scope.delete = function(product) {
		Product.delete({
			id: product._id
		});
		_.each($scope.products, function(o, i) {
			if (o === product) {
				$scope.products.splice(i, 1);
			}
		});
	};
	$scope.searchProducts = function(topLevel) {
		if (!topLevel) {
			return
		}
		if ($scope.configs.productPageSize){
			$scope.ctx.productPageSize = parseInt($scope.configs.productPageSize)
		}
		var params = {limit:$scope.ctx.productPageSize};
		if ($scope.productNameFilter &&  $scope.productNameFilter != "") {
			params.term = $scope.productNameFilter
		}
		if ($scope.ctx.type && $scope.type != ''){
			params.type = $scope.ctx.type;
		}
		if ($scope.ctx.deleted != ''){
			params.deleted = $scope.ctx.deleted;
		}
		if ($scope.ctx.isAggregate != ''){
			params.isAggregate = $scope.ctx.isAggregate;
		}
		if ($scope.ctx.duplicate != ''){
			params.duplicate = $scope.ctx.duplicate;
		}
		if ($scope.ctx.subtype && $scope.ctx.subtype != '') {
			params.subtype = $scope.ctx.subtype
		}
		if ($scope.ctx.ventMaterials && $scope.ctx.ventMaterials != '') {
			params.ventMaterials = $scope.ctx.ventMaterials
		}
		if ($scope.ctx.dim1 && $scope.ctx.dim1 != 0 && $scope.ctx.dim1 != "") {
			params.dim1 = $scope.ctx.dim1
		}
		if ($scope.ctx.couplerDim1 && $scope.ctx.couplerDim1 != 0 && $scope.ctx.couplerDim1 != "") {
			params['coupler.dim1'] = $scope.ctx.couplerDim1
		}
		if ($scope.ctx.fitting && $scope.ctx.fitting != "") {
			params.fitting = $scope.ctx.fitting
		}
		if ($scope.ctx.baffle && $scope.ctx.baffle != "") {
			params.baffle = $scope.ctx.baffle
		}
		if ($scope.ctx.outerMetalType && $scope.ctx.outerMetalType != '') {
			params.outerMetalType = $scope.ctx.outerMetalType
		}
		if ($scope.ctx.manufacturer && $scope.ctx.manufacturer != '') {
			params.manufacturer = $scope.ctx.manufacturer
		}
		if ($scope.ctx.innerMetalType && $scope.ctx.innerMetalType != '') {
			params.innerMetalType = $scope.ctx.innerMetalType
		}
		if ($scope.ctx.insulationType && $scope.ctx.insulationType != '') {
			params.insulationType = $scope.ctx.insulationType
		}
		Flash.clear()
		Flash.create("info", '<strong>Search Products...</strong>', 0, {}, true);
		Product.query(params).$promise.then(function(products){
			$scope.products = products
			if (products.length == $scope.ctx.productPageSize) {
				Flash.clear()

				var message = "Showing the max " + products.length + " products, please narrow your search";
				Flash.create('danger', '<strong>Warnging!</strong> ' + message, 0, {}, true);
			} else {
				Flash.clear()
				Flash.create("info", '<strong>Search Products</strong> found ' + products.length + ' items.', 0, {}, true);
			}
		});
	}
	$scope.export = function(e) {
		var params = [];
		if ($scope.productNameFilter && $scope.productNameFilter != '') {
			params.push('term=' + $scope.productNameFilter)
		}
		if ($scope.ctx.type && $scope.ctx.type != ''){
			params.push("type="+$scope.ctx.type)
		}
		
		if ($scope.ctx.isAggregate && $scope.ctx.isAggregate != ''){
			params.push("isAggregate="+$scope.ctx.isAggregate)
		}
		if ($scope.ctx.deleted && $scope.ctx.deleted != ''){
			params.push("deleted="+$scope.ctx.deleted)
		}
		if ($scope.ctx.duplicate && $scope.ctx.duplicate != ''){
			params.push("duplicate="+$scope.ctx.duplicate)
		}
		if ($scope.ctx.subtype && $scope.ctx.subtype != ''){
			params.push("subtype="+$scope.ctx.subtype)
		}
		if ($scope.ctx.ventMaterials && $scope.ctx.ventMaterials != ''){
			params.push("ventMaterials="+$scope.ctx.ventMaterials)
		}
		if ($scope.ctx.dim1 && $scope.ctx.dim1 != '' && $scope.ctx.dim1 != 0){
			params.push("dim1="+$scope.ctx.dim1)
		}
		if ($scope.ctx.couplerDim1 && $scope.ctx.couplerDim1 != '' && $scope.ctx.couplerDim1 != 0){
			params.push("coupler.dim1="+$scope.ctx.couplerDim1)
		}
		if ($scope.ctx.fitting && $scope.ctx.fitting != ''){
			params.push("fitting="+$scope.ctx.fitting)
		}
		if ($scope.ctx.baffle && $scope.ctx.baffle != ''){
			params.push("baffle="+$scope.ctx.baffle)
		}
		if ($scope.ctx.outerMetalType && $scope.ctx.outerMetalType != ''){
			params.push("outerMetalType="+$scope.ctx.outerMetalType)
		}
		if ($scope.ctx.manufacturer && $scope.ctx.manufacturer != ''){
			params.push("manufacturer="+$scope.ctx.manufacturer)
		}
		if ($scope.ctx.innerMetalType && $scope.ctx.innerMetalType != ''){
			params.push("innerMetalType="+$scope.ctx.innerMetalType)
		}
		if ($scope.ctx.insulationType && $scope.ctx.insulationType != ''){
			params.push("insulationType="+$scope.ctx.insulationType)
		}
		var url = "/api/products/export.csv";
		if (params.length > 0) {
			url = url + "?" + params.join("&")
		}
		window.location.href = url;
	}
	$scope.uploader = new FileUploader({});
	function pollTask(systemTask) {
		$scope.systemTask = systemTask
		var diff = new Date().getTime() - new Date(systemTask.startedAt).getTime()
		var level = "warning"
		var additionalNotes = ""
		if ($scope.systemTask.status == "running") {
			additionalNotes = " started " + diff/1000 + " seconds ago"
		} else if ($scope.systemTask.status == "success") {
			level = "info"
		} else if ($scope.systemTask.status == "errored") {
			level = "danger"
		}

		Flash.clear()
		var anchor = '<a href="/system-tasks/' + $scope.systemTask._id + '">' + $scope.systemTask.taskNumber + '</a>'
		Flash.create(level, '<strong>Import system task ' + anchor + 
			" is " + $scope.systemTask.status + '</strong> ' + additionalNotes + $scope.systemTask.notes, 0, {}, true);
		setTimeout(function() {
			if ($scope.systemTask && $scope.systemTask.status == "running") {
				SystemTask.get({
					id: $scope.systemTask.id
				}).$promise.then(function(res) {
					pollTask(res)
				})
			}
		}, 5000)
	}
	$scope.import = function() {
		$scope.systemTask = null
		_.each($scope.uploader.queue, function(data){
			var fd = new FormData();
			fd.append('name', data._file.name);
			fd.append('asset', data._file);
			$scope.uploadResponse = false;
			Flash.clear()
			Product.import(fd).$promise.then(function(res){
				pollTask(res)
				// $scope.uploadResponse = res
				$scope.uploader.clearQueue()
			}).catch(function(e){
				var message = "";
				if (e.data ) {
					message = message + e.data.message
				} else {
					message = message + e
				}
				Flash.create('danger', '<strong>Failure!</strong> ' + message, 0, {}, true);
				$scope.uploader.clearQueue()
			})
		});
	}
});
